import { isRouteErrorResponse, useRouteError } from '@remix-run/react';
import ImageError from '~/assets/error.svg';
import ServerError from '~/assets/error-500.svg';
import Error401 from '~/assets/error-401.svg';
import Error403 from '~/assets/error-403.svg';
import Error404 from '~/assets/error-404.svg';
import { PrimaryNavLinkButton } from './primary-nav-link-button';

export function GeneralErrorBoundary() {
  const error = useRouteError();

  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="flex max-w-[425px] flex-col items-center">
        {/* @ts-ignore */}
        <ErrorImage status={error.status} />
        {/* @ts-ignore */}
        <ErrorContent status={error.status} />
        <DebugLogs error={error} />
      </div>
    </div>
  );
}

type ErrorImageType = {
  status: number;
};

function ErrorImage({ status }: ErrorImageType) {
  let src = null;

  switch (status) {
    case 401:
      src = Error401;
      break;
    case 403:
      src = Error403;
      break;
    case 404:
      src = Error404;
      break;
    default:
      src = ImageError;
  }

  if (status >= 500) {
    src = ServerError;
  }

  return <img src={src} alt="Insomnia server error logo" />;
}

type ContentType = {
  status: number;
};

function ErrorContent({ status }: ContentType) {
  let title = '';
  let desc = '';

  switch (status) {
    case 404:
      title = 'Page Not Found';
      desc = `We couldn't find the page you were looking for. Maybe it was never here in the first place.`;
      break;
    default:
      title = 'Something went wrong';
      desc = 'We are already working on fixing it!';
  }

  return (
    <>
      <p className="m-0 mb-3 mt-8 text-[22px] font-normal leading-[28px] tracking-[-0.25px] text-black">{title}</p>
      <p className="m-0 text-center text-[14px] font-normal leading-[18px] tracking-[-0.25px] text-[#000000d9]">
        {desc}
      </p>
      <PrimaryNavLinkButton to="/" className="mx-[0px] my-[20px] h-[35px] w-[150px]">
        Go to dashboard
      </PrimaryNavLinkButton>
    </>
  );
}

type DebugLogsType = {
  error: unknown;
};

function DebugLogs({ error }: DebugLogsType) {
  if (process.env.NODE_ENV !== 'development') return null;

  // Status handler
  if (isRouteErrorResponse(error)) {
    return (
      <pre style={{ width: '100%' }}>
        <p className="m-0 text-[14px] font-[500] leading-[18px] tracking-[-0.25px] text-[#000000d9]">Debug Logs:</p>
        <code style={{ margin: '15px 0' }}>{`Backend responded with: ${error.status} ${error.statusText}`}</code>
        <code style={{ margin: '15px 0' }}>{JSON.stringify(error, null, 2)}</code>
      </pre>
    );
  }

  // Unexpected error handler
  return (
    <pre style={{ width: '100%' }}>
      <p className="m-0 text-[14px] font-[500] leading-[18px] tracking-[-0.25px] text-[#000000d9]">Debug Logs:</p>
      <code style={{ margin: '15px 0' }}>{getErrorMessage(error)}</code>
      <code style={{ margin: '15px 0' }}>{getErrorStack(error)}</code>
    </pre>
  );
}

function getErrorMessage(error: unknown) {
  if (error instanceof Error) return error.message;

  if (typeof error === 'string') return error;

  if (error && typeof error === 'object' && 'message' in error && typeof error.message === 'string') {
    return error.message;
  }

  console.error('Unable to get error message for error', error);

  return 'Unknown Error';
}

function getErrorStack(error: unknown) {
  if (error instanceof Error) return error.stack;

  if (typeof error === 'string') return error;

  if (error && typeof error === 'object' && 'stack' in error && typeof error.stack === 'string') {
    return error.stack;
  }

  console.error('Unable to get error stack for error', error);

  return 'Unknown Error';
}
